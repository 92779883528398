<template>
  <div v-for="(result, index) in results" :key="index">
    <div v-if="result.statusCode === 'WAITING'"
         class="relation-check-results">
      <h3>{{ result.childFio }}</h3>
      <i class="main-icon bi-hourglass"></i>
      <h4>{{ $lang.app.organization }}: {{ $lang.$translate({ru: result.organizationNameRu, kg: result.organizationNameKg}) }}</h4>
      <h4 v-if="result.organizationGroupName">{{ $lang.app.group }}: {{ result.organizationGroupName }}</h4>
      <h4>{{ $lang.app.application_waiting_director_confirm }}</h4>
    </div>
    <div v-if="result.statusCode === 'ENROLLED'"
         class="relation-check-results application-enroll">
      <h3>{{ result.childFio }}</h3>
      <i class="main-icon bi-check-all"></i>
      <h4>{{ $lang.app.application_enroll_text }}</h4>
      <h4 v-if="result.enrolledDate">{{ $lang.app.enroll_date }}: {{ $moment(result.enrolledDate).format('DD.MM.YYYY') }}</h4>
      <h4>{{ $lang.app.organization }}: {{ $lang.$translate({ru: result.organizationNameRu, kg: result.organizationNameKg}) }}</h4>
      <h4 v-if="result.organizationGroupName">{{ $lang.app.group }}: {{ result.organizationGroupName }}</h4>
    </div>
    <div v-if="result.statusCode === 'REJECTED'"
         class="relation-check-results application-rejected">
      <h3>{{ result.childFio }}</h3>
      <i class="main-icon bi-x-circle"></i>
      <h4>{{ $lang.app.application_rejected_text }}</h4>
      <h4>{{ $lang.app.organization }}: {{ $lang.$translate({ru: result.organizationNameRu, kg: result.organizationNameKg}) }}</h4>
      <div v-if="result.rejectedReason" class="check-results-notice">
        <i class="check-results-notice-icon bi-exclamation-triangle-fill"></i>
        <div class="check-results-notice-text">
          <h4>{{ $lang.app.attention }}</h4>
          <p>
            <span>{{ result.rejectedReason }}</span> <br>
            <span v-if="result.rejectedTime">
                {{ $lang.app.rejected_time_text.replace(':date', $moment(result.rejectedTime).format('DD.MM.YYYY')).replace(':time', $moment(result.rejectedTime).format('HH:mm')) }}
              </span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="result.statusCode === 'DROPPED'"
         class="relation-check-results application-rejected">
      <h3>{{ result.childFio }}</h3>
      <i class="main-icon bi-x-circle"></i>
      <h4>{{ $lang.app.application_dropped }}</h4>
      <h4>{{ $lang.app.organization }}: {{ $lang.$translate({ru: result.organizationNameRu, kg: result.organizationNameKg}) }}</h4>
    </div>
    <div v-if="result.statusCode === 'NOT_FOUND'"
         class="relation-check-results application-not-found">
      <i class="main-icon bi-exclamation-circle"></i>
      <h4>{{ $lang.app.application_not_found_text }}</h4>
    </div>
  </div>
</template>
<script>
export default {
  props: ['results']
}
</script>
<style>
.relation-check-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  color: #4c9ac7;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.relation-check-results.application-not-found {
  color: darkorange;
}
.relation-check-results.application-enroll {
  color: mediumseagreen;
}
.relation-check-results.application-rejected {
  color: orangered;
}
.relation-check-results .main-icon {
  font-size: 100px;
  display: inline-block;
  margin-bottom: 20px;
}
.relation-check-results h3 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 20px 0 0;
}
.relation-check-results h4 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  font-weight: bold;
}
.check-results-notice {
  width: 100%;
  padding: 30px 0;
  display: flex;
}
.check-results-notice-icon {
  color: red;
  font-size: 48px;
  display: inline-block;
  margin-right: 30px;
}
.check-results-notice h4 {
  margin: 15px 0;
  text-transform: uppercase;
  font-weight: bold;
}
.check-results-notice-text {
  color: black;
  text-align: left;
}
@media screen and (max-width: 1200px) {
  .relation-check-results i {
    margin-bottom: 15px;
  }
  .relation-check-results h4 {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .relation-check-results {
    padding: 15px 0 25px;
  }
  .relation-check-results i {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 479px) {
  .relation-check-results i {
    font-size: 32px;
  }
}
</style>