<template>
  <div>
    <div class="applications">
      <button v-for="application in applications" :key="application.id"
              class="application" :class="{'active': currentApplication && currentApplication.id === application.id}"
              @click="setCurrentApplication(application)">{{ application.childFio }}</button>
    </div>
    <div class="status-result">
      <div v-if="results" class="check-results-block-container">
        <PreschoolApplicationStatusResult v-if="currentApplication.type === 'PRESCHOOL'" :results="results"/>
        <SchoolApplicationStatusResult v-if="currentApplication.type === 'SCHOOL'" :results="[results]"/>
      </div>
    </div>
  </div>
</template>
<script>
import PreschoolApplicationStatusResult from "../../../components/application-status/PreschoolApplicationStatusResult";
import SchoolApplicationStatusResult from "../../../components/application-status/SchoolApplicationStatusResult";
export default {
  components: {
    PreschoolApplicationStatusResult,
    SchoolApplicationStatusResult
  },
  data() {
    return {
      applications: [],
      page: null,
      links: null,
      currentApplication: null,
      results: null,
    }
  },
  created() {
    this.getApplications()
  },
  methods: {
    getApplications() {
      this.$axios.get(`/application/list/applicant/${this.$store.getters.getPersonId}`).then(({data}) => {
        this.applications = data._embedded ? data._embedded.applicationResourceList : []
        this.page = data.page
        this.links = data._links
      })
    },
    setCurrentApplication(application) {
      this.currentApplication = application
      this.checkStatus(application)
    },
    checkStatus(application) {
      if(application.type === 'PRESCHOOL') {
        this.checkStatusPreschool(application)
      }
      if(application.type === 'SCHOOL') {
        this.checkStatusSchool(application)
      }
    },
    async checkStatusPreschool(application) {
      this.$store.commit('startLoading')

      this.$axios.get(`/application/${application.id}/preschool-status`).then(({data}) => {
        this.results = data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    checkStatusSchool(application) {
      this.$store.commit('startLoading')

      this.$axios.get(`/application/${application.id}/school-status`).then(({data}) => {
        this.results = data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    }
  }
}
</script>
<style>
.applications {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.application {
  white-space: nowrap;
  border: 2px solid darkgray;
  padding: 10px 20px;
  border-radius: 25px;
  margin: 10px;
  color: #4c9ac7;
}
.application.active {
  border: 2px solid #4c9ac7;
}
</style>